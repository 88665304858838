import React, { Component } from 'react';
import {
  Row, Col, Card, CardBody
} from 'reactstrap';
import PageTitle from '../../components/PageTitle';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user'))
  };  
}


  componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        <Row className="page-title">
          <Col md={12}>
            <PageTitle
              breadCrumbItems={[
                { label: 'Home', path: '/' },
              ]}
              title={'Home'}
            />
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col>
               Welcome <b>{this.state.user.username}</b>, thank you for using my app! 
               Please be aware this app is still a <b><u>Work in Progress</u></b>, 
               should you have any issues or you would like to view the source code please send me an email: <a href="mailto:gfurnell20@googlemail.com">gfurnell20@googlemail.com</a>. For issues, if you could include any screenshots / logs that would be great!.
              </Col>
            </Row>

          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
export default Home;
