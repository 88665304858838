/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = async (url, options = {}) => {
    return fetch(url, options)
        .then(async response => {
            
            if(response.status === 201) {
                return response;
            }

            if (!response.ok) {
                throw await response.json()
            }
            
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

export { fetchJSON };
