// @flow
const test = () => {
    const options = {
        method: 'POST',
        credentials: 'include',
    };
    
    fetch(process.env.REACT_APP_API_URL + '/auth/get', options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return true;
        })
        .then(json => {
            return true;
        })
        .catch(error => {
            throw error;
        });
}
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user || new Date(user.expiry).getTime() < new Date().getTime()) {
        localStorage.removeItem('user')
        return false;
    }
    return true;
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser, test };
